:root {
  --themeOrange: #da2c2e;
  --themeOrangeLight: #eb7404;
  --themeDarkBlue: #052b6a;

}


.MuiInputBase-input {
  height: 10px !important;
}

.MuiTab-fullWidth {
  border: 1px solid #9c27b0 !important;
  color: black !important;
  font-weight: bold !important;
}

.custom-filter-btn {
  color: black !important;
  font-weight: bold !important;
  background-color: #efefef !important;
}

.custom-select-height .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
  min-height: 0px !important;
}

.custom-select-height .MuiFormControl-root label {
  margin-top: -3px !important;
}

.custom-pagination-class .MuiPagination-ul {
  justify-content: end !important;
}


/* Popup container styles */
#popup {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

/* Input box styles */
#popup-input {
  width: 150px;
  padding: 5px;
  margin-bottom: 10px;
}

/* Save button styles */
#popup-save {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

#popup-save:hover {
  background-color: #0056b3;
}

/* Cancel button styles */
#popup-cancel {
  padding: 5px 10px;
  background-color: #ccc;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

#popup-cancel:hover {
  background-color: #999;
}


.scroll-container::-webkit-scrollbar {
  width: 10px;
  /* Change the scrollbar width */
  height: 10px;
  /* Change the scrollbar height */
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Change the track's background color */
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  /* Add rounded corners to the thumb */
}

.scroll-container {
  scroll-snap-type: y mandatory;
  /* Enable vertical scroll snapping */
  scroll-behavior: smooth;
}

.Dropdown-menu::-webkit-scrollbar {
  width: 10px;
  /* Change the scrollbar width */
  height: 10px;
  /* Change the scrollbar height */
}

.Dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Change the track's background color */
}

.Dropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  /* Add rounded corners to the thumb */
}

.Dropdown-menu {
  scroll-snap-type: y mandatory;
  /* Enable vertical scroll snapping */
  scroll-behavior: smooth;
}


.custom-error-label {
  font-size: 10px;
  color: red;
  margin-bottom: 0px !important;
}

.custom-error-validation {
  color: red;
  font-size: 11px;
  font-weight: bold;
  text-align: left;

}

.custom-tag-input {
  width: 100% !important;
}

.custom-tag-chip-overflow {
  height: 100px;
  overflow: scroll;
}


.beads-info {
  font-size: 16px;
}

.beads-amount {
  font-weight: bold;
  color: #007bff;
}

.ngn-amount {
  font-weight: bold;
  color: #28a745;
}

.custom-input {
  border: none;
  border-bottom: 2px solid #000;
  outline: none;
  background-color: transparent;
  width: 70px;
  color: #000;
  font-size: 16px;
  padding-left: 3px;
}


.custom-td-width {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



.modal-header-custom .btn-close {
  width: 32px;
  height: 32px;
  border: 1px solid #ddd;
  border-radius: 100px;
  position: relative;
}

.modal-header-custom .btn-close:after {
  content: "";
  width: 20px;
  height: 2px;
  background: #000;
  position: absolute;
  left: 5px;
  transform: rotate(45deg);
  top: 14px;
}

.modal-header-custom .btn-close:before {
  content: "";
  width: 20px;
  height: 2px;
  background: #000;
  position: absolute;
  right: 4px;
  transform: rotate(135deg);
  top: 14px;
}



/* ========new_css========== */

body {
  min-height: 100vh;
}

/* .bg-gradient-primary {
  background: linear-gradient(87deg, #191a20 0, #353740 100%) !important;
} */

.navbar-vertical.navbar-expand-md.fixed-left {
  background: linear-gradient(1deg, #151314, #231f20) !important;
  /* background: #052b6a !important; */
}

.bg-danger {
  background: linear-gradient(1deg, #ffac1c, #fed301) !important;
}

.main-content .navbar-top {
  background: linear-gradient(1deg, #ffac1c, #fed301);
  font-weight: bold;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.2);
}


.bg-theme-color {
  background-color: #202128 !important;
}


.ck-balloon-panel_visible {
  z-index: 1200 !important;
}





/* reset css */

.btn-primary {
  border: none;
  background: linear-gradient(1deg, #ffac1c, #fed301) !important;
}

.dropdown-menu {
  max-height: 233px !important;
  overflow: auto !important;
}

.ck-editor__main {
  max-height: 300px !important;
  overflow: auto;
}



.left-side {
  flex: 1;
  padding-right: 20px;
}

.left-side table {
  width: 100%;
  border-collapse: collapse;
}

.left-side th,
.left-side td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.left-side th {
  font-weight: bold;
}

.right-side {
  /* flex: 1;
  display: flex; */
  justify-content: center;
  align-items: center;
}

.right-side img,
.right-side video {
  max-width: 100%;
  max-height: 300px;
  border-radius: 10px;
}

.modal-body-child {
  display: flex;
  padding: 20px;
}


.custom-matrix-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-matrix-th,
.custom-matrix-td {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}

.custom-matrix-th {
  background-color: #f2f2f2;
}

.text-small {
  font-size: 13px;
  font-weight: 600;
}